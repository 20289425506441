import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import Home from "./components/Home";
import { Navigation } from "./components/general/Navigation";
import HR from "./components/HR";
import { ErrorCode, ErrorWithCode, TeamsUserCredential, TeamsUserCredentialAuthConfig } from "@microsoft/teamsfx";
import MindfulMorning from "./components/MindfulMorning";
import WelcomeWizard from "./components/WelcomeWizard";
import Introduction from "./components/welcome-wizard/Introduction";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import Survey from "./components/home/Survey";
import WelcomeSurvey from "./components/welcome-wizard/Survey";
import Preferences from "./components/welcome-wizard/Preferences";
import Home2 from "./components/Home2";
import { app } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";
import { printIntrospectionSchema } from "graphql";
import HRDashboard from "./v3/dashboards/HRDashboard/HRDashboard";

function App() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [token, setToken] = useState("");
  const [platformId, setPlatformId] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [user, setUser] = useState<any>();

  const [filters, setFilters] = useState({
    departments: [],
    entities: [],
    jobTitles: [],
    countries: [],
    view: 'week'
  });

  // dotenv.config();
  console.log(import.meta.env.VITE_VERSION);
  console.log("mode: " + import.meta.env.MODE);
  //console.log(process.env);
  useEffect(() => {
    getContext();
    login();
    if (import.meta.env.MODE === 'development') {
      setToken("dev");
    }
  }, []);

  const getContext = async () => {
    app.initialize();
    const context: app.Context = await microsoftTeams.app.getContext();
    console.log("context: " + JSON.stringify(context));
    console.log("subpageId: " + context.page.subPageId);

    if (context.page.subPageId === "mindful-morning") {
      navigate("/mindful-morning");
    } else if (context.page.subPageId?.includes('hr-survey')) {
      navigate("/survey/" + context.page.subPageId.split('-').pop());
    } else if (context.page.subPageId?.includes('survey')){
      navigate("/survey/" + context.page.subPageId.split('-').pop());
    }
  }

  const login = async () => {
    try {
      const authConfig: TeamsUserCredentialAuthConfig = {
        clientId: import.meta.env.VITE_CLIENT_ID,
        initiateLoginEndpoint: import.meta.env.VITE_START_LOGIN_PAGE_URL,
      };



      const credential = new TeamsUserCredential(authConfig);

      console.log("credential: " + JSON.stringify(credential));
      const userInfo = await credential.getUserInfo();

      console.log(userInfo);
      console.log("credential after userInfo: " + JSON.stringify(credential));

      if (userInfo === null) {
        //const loginObj = await credential.login("offline_access");
        const loginObj = await credential.login("https://graph.microsoft.com/.default");
        console.log("loginObj: " + JSON.stringify(loginObj));
        console.log("credential after login: " + JSON.stringify(credential));
      }

      // const loginObj = await credential.login("https://graph.microsoft.com/.default");
      // console.log("login obj defailt: " + JSON.stringify(loginObj));

      let credentialObj = JSON.parse(JSON.stringify(credential));
      let ssoToken = credentialObj.ssoToken.token;

      // const graphEndpoint = 'https://graph.microsoft.com/v1.0/me';

      // const response = await fetch(graphEndpoint, {
      //   headers: {
      //     'Authorization': `Bearer ${ssoToken}`,
      //   },
      // });

      // if (response.ok) {
      //   const userData = await response.json();
      //   console.log("userdata graph: " + userData);
      // } else {
      //   // Handle the case where the API request failed
      // }

      const displayName = userInfo.displayName;
      let firstName = "";
      if (/^[A-Z][a-z]+ [A-Z][a-z]+$/.test(displayName)) {
        // Format is "Firstname Lastname"
        const nameParts = displayName.split(' ');
        firstName = nameParts[0];
      } else if (/^[A-Z][a-z]+, [A-Z][a-z]+$/.test(displayName)) {
        // Format is "Lastname, Firstname"
        const nameParts = displayName.split(', ');
        firstName = nameParts[1];
      } else {
        // Handle other cases or invalid input
      }
      setFullName(userInfo.displayName);
      setUsername(firstName);
      setPlatformId(userInfo.objectId);
      setToken(ssoToken);
      //const token = await credential.getToken("offline_access");


      //console.log("token: " + JSON.stringify(token));
    } catch (err: unknown) {
      if (err instanceof ErrorWithCode && err.code !== ErrorCode.ConsentFailed) {
        throw err;
      } else {
        // Silently fail because user cancels the consent dialog
        return;
      }
    }
  }

  function onFilterChange(newFilters: any) {
    console.log("received new filters: " + JSON.stringify(newFilters))
    setFilters(newFilters);
  }

  return (

    <div className="App">
      {/* nav */}
      <Navigation username={username} fullName={fullName} profilePicture={profilePicture} token={token} onFilterChange={onFilterChange} filters={filters} user={user}/>
      {/* <NavLink to=""/> has active state */}
      {/* <Link to=""/> has no active state*/}
      <ToastContainer/>
      {(token === "") ? (
        <h1>LOADING</h1>
      ) : (
        <Routes>
           <Route path="/" element={<Home2 token={token} platformId={platformId} setUser={setUser} />} />
           {/* <Route path="/hr" element={<HR token={token} filters={filters} />} /> */}
           <Route path="/hr" element={<HRDashboard/>} />
           <Route path="/mindful-morning" element={<MindfulMorning token={token} platformId={platformId} username={username} user={user}/>} />
           <Route path="/survey/:surveyId" element={<Survey token={token} platformId={platformId} />} />
           <Route path="/welcome-wizard" element={<WelcomeWizard token={token} />} />
           <Route path="/welcome-wizard/introduction" element={<Introduction token={token} user={user}/>} />
           <Route path="/welcome-wizard/survey" element={<WelcomeSurvey token={token} platformId={platformId} />} />
           <Route path="/welcome-wizard/preferences" element={<Preferences token={token} />} />
           {/* <Route path="*" element={}/> */}
         </Routes>
      )}
      {/* footer */}
    </div>


  )
}

export default App
