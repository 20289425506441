import styles from "./energyDashboardTab.module.scss";

import AdvancedLineChart from "../components/AdvancedLineChart/AdvancedLineChart";
import EnergyBalanceGauge from "./components/EnergyBalanceGauge";
import EnergyByDepartment from "./components/EnergyByDepartment";
import EnergyByJobTitle from "./components/EnergyByJobTitle";
import EngagementChart from "../components/EngagementChart";

const EnergyDashboardTab = () => {
  return (
    <div className={styles.energyDashboardTab}>
      <div className={styles.row}>
        <div className={styles.col}>
          <EnergyBalanceGauge />
          <EngagementChart activeMonth={2} activeWeek={3} totalUsers={5}/>
        </div>
        <div className={styles.col}>
          <AdvancedLineChart />
          <div className={styles.row}>
            <EnergyByJobTitle />
            <EnergyByDepartment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyDashboardTab;
