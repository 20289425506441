import styles from './engagementChart.module.scss';

const EngagementChart = ({ activeMonth, activeWeek, totalUsers } : UserEngagementProps) => {
  return (
    <div className={styles.engagementContainer}>
      <div className={styles.totalUsers}>
        <span className={styles.totalLabel}>USER ENGAGEMENT</span>
        <span className={styles.totalCount}>({totalUsers} People)</span>
      </div>
      <div className={styles.chart}>
        <div className={styles.activeMonth} style={{ width: `${(activeMonth / totalUsers) * 100}%` }}>
          <span className={styles.userCount}>{activeMonth} <span>People</span></span>
        </div>
        <div className={styles.activeWeek} style={{ width: `${(activeWeek / totalUsers) * 100}%` }}>
          <span className={styles.userCount}>{activeWeek} <span>People</span></span>
        </div>
      </div>
      <div className={styles.labels}>
        <span className={styles.label}>Active past month</span>
        <span className={styles.label}>Active past week</span>
      </div>
    </div>
  );
};

export default EngagementChart;
