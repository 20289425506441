import { useEffect, useState } from 'react'
import { ReactComponent as SentimentCounterIcon } from '../../assets/icons/icon-sentiment-counter.svg';

import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import DropdownSentiment from "./DropdownSentiment";
import Dropdown from "../general/Dropdown";

function Sentiment(props: any) {
    const [user, setUser] = useState<any>(props.user);
    const [sentiments, setSentiments] = useState<any>();
    const [sentimentHistory, setSentimentHistory] = useState<any>();
    const [selectedValueSentimentdropdown, setSelectedValueSentimentDropdown] = useState<any>(6);
    const [selectedValueDropdown, setSelectedValueDropdown] = useState<any>('week');
    const [activeSentiment, setActiveSentiment] = useState<any>(6);
    console.log("active sentiment: " + activeSentiment);
    console.log("sentiments " + JSON.stringify(sentiments));
    useEffect(() => {
        getSentiment();
        getSentimentById(selectedValueSentimentdropdown);
    }, [])

    const getSentiment = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/GraphQL/sentiment?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setSentiments(data);
    }

    const getSentimentById = async (emotionId: any) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/GraphQL/sentiment/" + selectedValueDropdown + "?emotionId=" + emotionId + "&userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        var averages = data.averages.map((item: any) => ({ name: item.name, percentage: item.emotions[0].percentage }));
        console.log("percentages: " + JSON.stringify(averages));
        setSentimentHistory(averages);
    }

    const handleDropdownSentimentChange = async (event: any) => {
        console.log("selected value for dropdown sentiment: " + event.target.value);
        setSelectedValueSentimentDropdown(event.target.value);
        setActiveSentiment(event.target.value);
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }
        const response = await fetch("https://mindy-api.azurewebsites.net/GraphQL/sentiment/" + selectedValueDropdown + "?emotionId=" + event.target.value + "&userId=" + user.userId, options);
        const data = await response.json();
        console.log(response);
        console.log(data);

        var averages = data.averages.map((item: any) => ({ name: item.name, percentage: item.emotions[0].percentage }));
        console.log("percentages: " + JSON.stringify(averages));
        setSentimentHistory(averages);
    }

    const handleDropdownChange = async (event: any) => {
        setSelectedValueDropdown(event.target.value);

        try {
            const options = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + props.token,
                },
            };


            const response = await fetch(
                "https://mindy-api.azurewebsites.net/GraphQL/sentiment/" + event.target.value + "?emotionId=" + activeSentiment,
                options
            );
            const responseData = await response.json();
            console.log(responseData);
            let averages;

            //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
            //console.log("active metric:" + JSON.stringify(activeMetric));

            averages = responseData.averages.map((item: any) => ({
                name: item.name,
                percentage: item.emotions[0].percentage
            }));

            console.log("averages: " + JSON.stringify(averages));
            setSentimentHistory(averages);

        } catch (error) {
            console.error("Error fetching work pattern history:", error);
        }
    }

    return (
        <div className="sentiment">
            <section className="sentiment__stats">
                <h2 className="sentiment__stats__title">Your <span>Sentiments</span></h2>
                <div className="sentiment__stats__container">
                    <ol className="sentiment__stats__container__list">
                        {
                            (!user || !sentiments) ? ("loading...") : (
                                sentiments.averages.map((sentiment: any) => (
                                    <li className={`${activeSentiment === sentiment.emotionId ? 'active' : ''}`}>{sentiment.percentage}% {sentiment.emotion}</li>
                                ))
                            )
                        }
                    </ol>
                </div>
            </section>
            <section className="sentiment__graph">
                <div className="sentiment__graph__header">
                    <h2 className="sentiment__graph__header__title">Sentiment <span>analyse</span></h2>
                    <div style={{ display: "flex", gap: 8 }}>
                        {(!user || !sentiments) ? ("Loading...") : (
                            <DropdownSentiment sentiments={sentiments} selectedValue={selectedValueSentimentdropdown} onDropdownChange={handleDropdownSentimentChange} />
                        )}
                        <Dropdown selectedValue={selectedValueDropdown} onDropdownChange={handleDropdownChange} />
                    </div>
                </div>
                {(!user || !sentiments) ? ("Loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.6}>
                        <ComposedChart data={sentimentHistory} >
                            <XAxis dataKey="name" />
                            <YAxis width={25} />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="percentage" barSize={30} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            {/* <Line type="monotone" dataKey="average" stroke="#01195D" label="average" legendType="line" /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}

            </section>
        </div>
    )
}

export default Sentiment