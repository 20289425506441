import ProgressBar from "../../components/ProgressBar";
import styles from "./energyByJobTitle.module.scss";
import energyBalanceData from "./energyByJobTitleData";

const EnergyByJobTitle = () => {
  return (
    <div className={styles.energyByJobTitle}>
      <div className={styles.header}>
        <h3 className={styles.title}>Energy balance by <span>job title</span></h3>
      </div>
      <div className={styles.list}>
        {energyBalanceData.map((item) => (
          <div className={styles.item} key={item.jobTitle}>
            <span className={styles.jobTitle}>{item.jobTitle}</span>
            <ProgressBar completed={item.energyBalance} />
            <span className={styles.energyBalance}>{item.energyBalance}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnergyByJobTitle;
