import { ReactComponent as EnergyDashboardIcon } from "../../../../assets/icons/icon-energy-dashboard.svg";
import { ReactComponent as WorkBehaviourIcon } from "../../../../assets/icons/icon-work-behaviour.svg";
import { ReactComponent as SentimentIcon } from "../../../../assets/icons/icon-sentiment.svg";
import { ReactComponent as IconSurveys } from "../../../../assets/icons/icon-survey-metrics.svg";
import { ReactComponent as IconQuests } from "../../../../assets/icons/nav/icon-quests.svg";
import { ReactComponent as IconInsights } from "../../../../assets/icons/nav/icon-quests.svg";
import { ReactComponent as IconExperiments } from "../../../../assets/icons/nav/icon-quests.svg";

const tabData: TabItem[] = [
    { Icon: EnergyDashboardIcon,title: "Energy Dashboard",shortTitle: "Energy",index: 0 },
    { Icon: WorkBehaviourIcon, title: "Work Behaviour", shortTitle: "Behaviour", index: 1 },
    { Icon: SentimentIcon, title: "Sentiment", shortTitle: "Sentiment", index: 2 },
    { Icon: IconSurveys, title: "Wellbeing surveys", shortTitle: "Surveys", index: 3 },
    { Icon: IconQuests, title: "Quests", shortTitle: "Quests", index: 4 },
    { Icon: IconInsights, title: "Insights", shortTitle: "Insights", index: 5 },
    { Icon: IconExperiments, title: "Experiments", shortTitle: "Experiments", index: 6 },
];

export default tabData;