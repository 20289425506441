import Gauge from '../../../../../components/Gauge';

import { ReactComponent as OrganisationIcon } from '../../../../../../assets/icons/icon-organisation.svg';
import { ReactComponent as ChargeIcon } from "../../../../../../assets/icons/icon-charge.svg"
import { ReactComponent as RepeatIcon } from "../../../../../../assets/icons/icon-repeat.svg"
import { ReactComponent as DangerIcon } from "../../../../../../assets/icons/icon-danger.svg"
import { ReactComponent as BatteryPlusIcon } from "../../../../../../assets/icons/icon-battery-plus.svg"

import styles from "./energyBalanceGauge.module.scss";


const EnergyBalanceGauge = () => {
    const energyHistory = {
        averages: 60
    };
    const energyStats = {
        totalAtRisk: 0,
        currentAtRisk: 0,
        recoveredSince: 5
    };

    return (
        <section className={styles.energyBalanceGauge}>
            <h2 className={styles.energyBalanceGauge__title}><OrganisationIcon />Mindthriven's Energy balance</h2>
            <Gauge/>
            <p className={styles.energyBalanceGauge__percentage}>{(!energyHistory) ? ("Loading...") : (Math.round(energyHistory.averages) + "%")}</p>
            <p className={styles.energyBalanceGauge__text}><ChargeIcon /> working sustainably</p>
            <ul className={styles.energyBalanceGauge__stats}>
                <li className={styles.energyBalanceGauge__stats__item}>
                    <h3 className={styles.energyBalanceGauge__stats__item__title}><RepeatIcon />{(!energyStats) ? ("...") : (energyStats.totalAtRisk)}</h3>
                    <p className={styles.energyBalanceGauge__stats__item__description}>Total people at risk </p>
                </li>
                <li className={styles.energyBalanceGauge__stats__item}>
                    <h3 className={styles.energyBalanceGauge__stats__item__title} style={{ color: '#E23B4F' }}><DangerIcon />{(!energyStats) ? ("...") : (energyStats.currentAtRisk)}</h3>
                    <p className={styles.energyBalanceGauge__stats__item__description}>People at risk now.</p>
                </li>
                <li className={styles.energyBalanceGauge__stats__item}>
                    <h3 className={styles.energyBalanceGauge__stats__item__title} style={{ color: '#086DFD' }}><BatteryPlusIcon />{(!energyStats) ? ("...") : (energyStats.recoveredSince)}</h3>
                    <p className={styles.energyBalanceGauge__stats__item__description}>Recovered since Mindy</p>
                </li>
            </ul>
        </section>
    );
};

export default EnergyBalanceGauge;