import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import styles from "./AdvancedLineChart.module.scss";
import CustomDot from "./components/CustomDot";

const dailyData = [
  { name: "Sun", uv: 30 },
  { name: "Mon", uv: 45 },
  { name: "Tue", uv: 20 },
  { name: "Wed", uv: 78 },
  { name: "Thu", uv: 55 },
  { name: "Fri", uv: 43 },
  { name: "Sat", uv: 50 },
];

// Fixed weekly data for the last 4 weeks
const weeklyData = [
  { name: "Week 1", uv: 60 },
  { name: "Week 2", uv: 72 },
  { name: "Week 3", uv: 45 },
  { name: "Week 4", uv: 68 },
];

// Using the previously defined monthly data
const monthlyData = [
  { name: "Jan", uv: 20 },
  { name: "Feb", uv: 18 },
  { name: "Mar", uv: 76 },
  { name: "Apr", uv: 54 },
  { name: "May", uv: 39 },
  { name: "Jun", uv: 59 },
  { name: "Jul", uv: 67 },
  { name: "Aug", uv: 58 },
  { name: "Sep", uv: 57 },
  { name: "Oct", uv: 64 },
  { name: "Nov", uv: 62 },
  { name: "Dec", uv: 79 },
];

const AdvancedLineChart = () => {
  const [activeDataset, setActiveDataset] = useState(monthlyData);
  const [activeButton, setActiveButton] = useState("Monthly");

  const handleButtonClick = (datasetName: any) => {
    setActiveButton(datasetName);
    switch (datasetName) {
      case "Daily":
        setActiveDataset(dailyData);
        break;
      case "Weekly":
        setActiveDataset(weeklyData);
        break;
      case "Monthly":
        setActiveDataset(monthlyData);
        break;
      default:
        setActiveDataset(monthlyData);
    }
  };

  return (
    <section className={styles.AdvancedLineChart}>
      <div className={styles.header}>
        <h3>
          Energy balance <span>over time</span>
        </h3>
        <div className={styles.actions}>
          <button
            className={`btn ${activeButton === "Daily" ? "active" : ""}`}
            onClick={() => handleButtonClick("Daily")}
          >
            Daily
          </button>
          <button
            className={`btn ${activeButton === "Weekly" ? "active" : ""}`}
            onClick={() => handleButtonClick("Weekly")}
          >
            Weekly
          </button>
          <button
            className={`btn ${activeButton === "Monthly" ? "active" : ""}`}
            onClick={() => handleButtonClick("Monthly")}
          >
            Monthly
          </button>
        </div>
      </div>
      <div className={styles.content}>
        <ResponsiveContainer width="100%" height={335}>
          <LineChart
            width={500}
            height={335}
            data={activeDataset}
            margin={{
              left: -5,
              bottom: 20,
              top: 36
            }}
          >
            <CartesianGrid
              vertical={false}
              horizontal={true}
              stroke="#DFDCEB"
            />
            <XAxis dataKey="name" axisLine={false} tick={{ fill: '#99A3BE', fontWeight: 'bold' }} tickLine={false} tickMargin={21} padding={{ right: 20 }} />
            <YAxis domain={[10, 100]} ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} tick={{ fill: '#99A3BE' }} axisLine={false} tickLine={false} tickMargin={21} />
            <Tooltip />
            <Line dataKey="uv" stroke="#01195D" dot={<CustomDot />} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default AdvancedLineChart;
