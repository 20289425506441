import { useState } from "react";
import Tabs from "./components/Tabs";
import TabContent from "./components/TabContent";
import tabData from "./components/tabData";

const HRDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <main className="hr">
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabData={tabData}
      />
      <TabContent activeTab={activeTab} />
    </main>
  );
};

export default HRDashboard;
