import React from 'react';
import Tab from './Tab';

const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab, tabData }) => {
  return (
    <ol className="tabs">
      {tabData.map((tab) => (
        <Tab
          key={tab.index}
          Icon={tab.Icon}
          title={tab.title}
          shortTitle={tab.shortTitle}
          isActive={activeTab === tab.index}
          onClick={() => setActiveTab(tab.index)}
        />
      ))}
    </ol>
  );
};

export default Tabs;
