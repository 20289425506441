import styles from "./progressBar.module.scss";

interface ProgressBarProps {
  completed: number;
}

const ProgressBar = ({ completed } : ProgressBarProps) => {
  const percentage = Math.min(100, Math.max(0, completed));

  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressBar}
        style={{ width: `${percentage}%`}} 
      />
    </div>
  );
};

export default ProgressBar;
