import React from 'react';
import EnergyDashboardTab from '../tabs/EnergyDashboardTab/EnergyDashboardTab';
import WorkBehaviourTab from '../tabs/WorkPatternsTab/WorkPatternsTab';
import QuestsTab from '../tabs/QuestsTab/QuestsTab';
import SentimentTab from '../tabs/SentimentTab/SentimentTab';
import WellbeingSurveysTab from '../tabs/WellbeingSurveysTab/WellbeingSurveysTab';
import InsightsTab from '../tabs/InsightsTab/InsightsTab';
import ExperimentsTab from '../tabs/ExperimentsTab/ExperimentsTab';

const TabContent: React.FC<TabContentProps> = ({ activeTab }) => {
  switch (activeTab) {
    case 0:
      return <EnergyDashboardTab />;
    case 1:
      return <WorkBehaviourTab />;
    case 2:
        return <QuestsTab/>;
    case 3:
        return <SentimentTab/>
    case 4:
        return <WellbeingSurveysTab/>
    case 5:
        return <InsightsTab/>
    case 6:
        return <ExperimentsTab/>
    default:
      return <div>Tab not found</div>;
  }
};

export default TabContent;
