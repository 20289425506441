type EnergyByJobTitleData = {
  jobTitle: string;
  energyBalance: number;
};

const energyByJobTitleData: EnergyByJobTitleData[] = [
  { jobTitle: "Accountant", energyBalance: 79 },
  { jobTitle: "Director", energyBalance: 76 },
  { jobTitle: "Marketing Manager", energyBalance: 62 },
  { jobTitle: "Product Manager", energyBalance: 59 },
  { jobTitle: "Retail Manager", energyBalance: 58 },
  { jobTitle: "Designer", energyBalance: 53 },
];

export default energyByJobTitleData;
