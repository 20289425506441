import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
//import * as Sentry from "@sentry/react";
import App from './App'
import './sass/main.scss';
// import { BrowserTracing } from '@sentry/react';


// Sentry.init({
//   dsn: "https://68f02113443c4b4d8308009c3758599c@sentry.phoenixnetwork.net/12",
//   integrations: [new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes ) }), new Sentry.Replay({
//     // Additional SDK configuration goes in here, for example:
//     maskAllText: true,
//     blockAllMedia: true,
//   }),],
//   debug: false,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 1.0,

//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
// });

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
