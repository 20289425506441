const CustomDot: React.FC<{
  cx?: number;
  cy?: number;
  stroke?: number;
  payload?: Object;
  value?: number;
}> = ({ cx, cy, stroke, payload, value }) => {
  console.log("value of custom dot received: " + value);
  console.log("payload of custom dot received: " + JSON.stringify(payload));
  if (typeof value === "number" && cy !== undefined && cx !== undefined) {
    if (value <= 25) {
      return <Dot25 value={value} cx={cx} cy={cy}/>;
    } else if (value <= 50) {
      return <Dot50 value={value} cx={cx} cy={cy}/>;
    } else if (value <= 75) {
      return <Dot75 value={value} cx={cx} cy={cy}/>;
    } else {
      return <Dot100 value={value} cx={cx} cy={cy}/>;
    }
  }

  return null;
};

export default CustomDot;

const Dot25: React.FC<DotProps> = ({ cx, cy, value }) => (
  <svg x={cx - 10} y={cy - 10} width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient1" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#EE596F", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#735DDA", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill="url(#gradient1)"
    />
    <text
      x="50%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Poppins"
      fontSize="14"
      fill="white"
      fontWeight="900"
    >
      {value}
    </text>
  </svg>
);

const Dot50: React.FC<DotProps> = ({ cx, cy, value }) => (
  <svg x={cx - 10} y={cy - 10} width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient2" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#735DDA", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#3879F9", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill="url(#gradient2)"
    />
    <text
      x="50%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Poppins"
      fontSize="14"
      fill="white"
      fontWeight="900"
    >
      {value}
    </text>
  </svg>
);

const Dot75: React.FC<DotProps> = ({ cx, cy, value }) => (
  <svg x={cx - 10} y={cy - 10} width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient3" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#3879F9", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#39BDFB", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill="url(#gradient3)"
    />
    <text
      x="50%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Poppins"
      fontSize="14"
      fill="white"
      fontWeight="900"
    >
      {value}
    </text>
  </svg>
);

const Dot100: React.FC<DotProps> = ({ cx, cy, value }) => (
  <svg x={cx - 10} y={cy - 10} width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient4" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#39BDFB", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#66DDA5", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill="url(#gradient4)"
    />
    <text
      x="50%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Poppins"
      fontSize="14"
      fill="white"
      fontWeight="900"
    >
      {value}
    </text>
  </svg>
);
