import MeterProps from "../../assets/img/meter-props.png";
import { ReactComponent as MeterNeedle } from "../../assets/img/meter-needle.svg";

const Gauge = () => {

const energyHistory = {averages: 60};

  return (
    <div className="meter-wrapper">
      {/* <MeterProps className="meter-props" /> */}
      <img
        src={MeterProps}
        className="meter-props"
        style={{ pointerEvents: "none" }}
      ></img>
      <MeterNeedle
        className="meter-needle"
        style={
          !energyHistory
            ? { ["--user-energy-meter" as any]: "-130deg" }
            : {
                ["--user-energy-meter" as any]:
                  energyHistory.averages * 2.6 - 130 + "deg",
              }
        }
      />
    </div>
  );
};

export default Gauge;
