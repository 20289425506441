import React from 'react';

const Tab: React.FC<TabProps> = ({ Icon, title, shortTitle, isActive, onClick }) => {
  const mediaQuery = window.matchMedia("(max-width: 767px)");
  const displayTitle = mediaQuery.matches ? shortTitle : title;

  return (
    <li className={`tabs__title ${isActive ? 'active' : ''}`} onClick={onClick}>
      <Icon />
      {displayTitle}
    </li>
  );
};

export default Tab;
